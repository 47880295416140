.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.aboutext {
    margin-left: 60px;
    margin-right: 60px;
}

.aboutext h1 {
    color: #FFF;
    text-align: left;
    font-family: 'Mont Heavy DEMO';
    font-size: 3rem;
    margin-left: 7rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.aboutext p {
    color: white;
    text-align: left;
    font-family: 'Montserrat', 'sans serif';
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.aboutext p2 {
    color: #c90505;
    text-align: left;
    font-family: 'Mont Heavy DEMO';
    font-size: 1.3rem;
}

.aboutext p3 {
    color: rgba(255, 255, 255, 0.705);
    text-align: right;
    font-family: 'Montserrat', 'sans serif';
    font-weight: 600;
    font-size: 0.6rem;
    margin-right: 3rem;
    margin-bottom: 0.4rem;
}

.container .face {
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    width: 17rem;
    height: 17rem;
}

.container .cross {
    margin-top: 3rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 11.5rem;
    height: 10rem;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .container .face {
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
        width: 17rem;
        height: 17rem;
    }
    .aboutext {
        margin-left: 30px;
        margin-right: 30px;
    }
    
  }