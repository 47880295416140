:root {
  --white: #fff;
  --active-color: #1f1f1f;
}
@font-face {
  font-family: "Mont Heavy DEMO";
  src: url("./assets/Mont_Heavy_DEMO/Mont-HeavyDEMO.eot") format("eot"), url("./assets/Mont_Heavy_DEMO/Mont-HeavyDEMO.woff") format("woff"), url("./assets/Mont_Heavy_DEMO/Mont-HeavyDEMO.ttf") format("truetype"), url("./assets/Mont_Heavy_DEMO/Mont-HeavyDEMO.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 14px;  /* Remove scrollbar space */
  height: 14px;
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ffffff57;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  background:rgba(0, 0, 0, 1)
}

html {
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
  background: var(--active-color);
  transition: background 0.4s ease;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: url("https://images.unsplash.com/photo-1568781269551-3e3baf5ec909?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

}

button {
  background: none;
  border: none;
  cursor: pointer;
}

input[type="range"] {
  height: 5px;
  -webkit-appearance: none;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
  background: #822b2b;
  transition: background 0.2s ease;
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #ff0000;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
}

.audio-player {
  max-width: 350px;
  height: 427px;
  padding: 24px;
  margin: 30px;
  border: 0px;
  border-top: 1.5px solid rgba(255, 255, 255, 0.18);
  border-left: 2px solid rgba(255, 255, 255, 0.18);
  border-radius: 16px;
  box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.15);
  background: rgba(255, 255, 255, 0.075);
  backdrop-filter: blur(3px) saturate(110%);
}

.artwork {
  border-radius: 10px;
  display: block;
  margin: auto;
  height: 200px;
  width: 200px;
}

.track-info {
  text-align: center;
  z-index: 1;
  position: relative;
}

.title {
  max-width: 200px;
  font-weight: 700;
  margin-bottom: 4px;
  color: var(--white);
  font-family: Arial, Helvetica, sans-serif;

  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.artist {
  font-weight: 300;
  margin-top: 0;
  color: var(--white);
  font-family: 'Mont Heavy DEMO';
  font-weight: 600;
}

.audio-controls {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto 15px;
}

.audio-controls .prev svg,
.audio-controls .next svg {
  width: 35px;
  height: 35px;
}

.audio-controls .play svg,
.audio-controls .pause svg {
  height: 40px;
  width: 40px;
}

.audio-controls path {
  fill: var(--white);
}

.color-backdrop {
  background: linear-gradient(45deg, var(--active-color) 20%, transparent 100%)
    no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}


.audiolink {
  max-width: 350px;
  padding: 14px;
  border-radius: 10px;
  border-color: "#000000";
  border-width: 1px;
  margin: auto;
  color: var(--white);
}

.links-container {
  width: 500px;
  max-height: 70%;
  overflow-y: scroll;
}

.audio-stuff {
  width:100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .audio-stuff {
    flex-direction: column;
  }
  .links-container {
    min-width: 300px;
    width: 100%;
    overflow-y: auto;
  }
  .face {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}