._3drDv {
    box-shadow: none;
    z-index: 10;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
}

._4sag1 {
    box-shadow: none;
}

._36iSm a {
    font-family: "Mont Heavy DEMO";
    letter-spacing: 0px;
    font-size: 30px;
    user-select: none;
}

._29JqD * {
    margin-right: 30px;
}